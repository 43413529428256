<template>
  <div class="divBox">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="新增" @click="toAdd"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="企业名称" v-model="searchParam.name" />
        <v-input label="联系人" v-model="searchParam.contactName" />
        <v-input label="手机号" v-model="searchParam.contactMobile" />
        <v-select
          clearable
          :options="teacherSignList"
          v-model="searchParam.serviceType"
          @change="$refs.list.search()"
          label="服务分类"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          text="编辑"
          v-if="scope.row.canEdit == undefined || scope.row.canEdit"
          type="text"
          @click="toEdit(scope.row)"
        />
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
      </template>
    </v-list>
  </div>
</template>

<script>
import { getLeagueListUrl, getLeagueInfoURL, deleteLeagueURL } from "./api.js";

import { createAlinkVNode, createImgVNode } from "@/utils/utils.js";

export default {
  name: "topicList",
  data() {
    return {
      searchParam: {
        contactMobile: "",
        contactName: "",
        serviceType: "",
        name: "",
      },
      teacherSignList: [],
      tableUrl: getLeagueListUrl,
      headers: [
        {
          prop: "serviceType",
          label: "服务分类",
        },
        {
          prop: "picture",
          label: "图片",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },
        {
          prop: "name",
          label: "企业名称",
        },
        {
          prop: "sort",
          label: "排序",
        },
        {
          prop: "contactName",
          label: "联系人",
        },
        {
          prop: "contactMobile",
          label: "手机号",
        },
      ],
    };
  },
  computed: {},
  mounted() {
    this.getTeacherInfo();
    // this.getTalentType();
  },
  methods: {
    toDelete(item) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          ids: item.id,
        };
        this.$axios
          .post(`${deleteLeagueURL}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$refs.list.search();
            } else {
            }
          });
      });
    },
    toAdd() {
      this.$router.push({
        name: "serviceLeagueForm",
      });
    },
    toEdit(row) {
      this.$router.push({
        name: "serviceLeagueForm",
        query: { id: row.id, communityId: row.communityId },
      });
    },

    getTeacherInfo() {
      let params = {};
      this.teacherSignList = [];
      this.$axios.get(`${getLeagueInfoURL}`, { params }).then((res) => {
        if (res.code === 200) {
          if (res.data && res.data.businessServiceTypeList) {
            res.data.businessServiceTypeList.forEach((item) => {
              item.label = item.dictValue;
              item.value = item.dictKey;
              this.teacherSignList.push(item);
            });
          }
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
}
</style>
