let origin = "https://future.zhuneng.cn";
//服务联盟列表
const getLeagueListUrl = `/gateway/hc-business/manageapi/league/getList`;

// 服务联盟详情
const getLeagueDetailURL = `/gateway/hc-business/manageapi/league/detail`;
//新增服务联盟
const addLeagueURL = `/gateway/hc-business/manageapi/league/submit`;
//创业服务分类下拉框列表查询
const getLeagueInfoURL = `/gateway/hc-business/manageapi/league/getInitInfo`;
//删除创业导师
const deleteLeagueURL = `/gateway/hc-business/manageapi/league/delete`;
//编辑手机号
const leagueEdit = `/gateway/hc-business/manageapi/league/leagueEdit`;
export {
  getLeagueListUrl,
  getLeagueDetailURL,
  addLeagueURL,
  getLeagueInfoURL,
  deleteLeagueURL,
  leagueEdit,
};
